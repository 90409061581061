import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const inputSwitcher = (inputType, props) => {
  switch(inputType) {
    case "textarea":
      return <textarea {...props} />
    default:
      return <InputMask type={inputType} {...props} />
  }
}

const Input = ({ className, type, icon, iconPosition, label, ...props }) => {
  const addAllClasses = ['input_element'];
  const inputType = type ? type : 'text';

  if (className) {
    addAllClasses.push(className);
  }
  if (icon && iconPosition) {
    addAllClasses.push(`icon-${iconPosition}`);
  }

  return (
    <div className={addAllClasses.join(' ')}>
      <span className="input-label">{label}</span>
      {
        inputSwitcher(inputType, props)
      }
      {icon && <span className="input-icon">{icon}</span>}
    </div>
  );
};

export default Input;

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'email',
    'password',
    'tel',
    'date',
    'checkbox',
  ]),
  icon: PropTypes.object,
  iconPosition: PropTypes.oneOf(['left', 'right'])
};

Input.defaultProps = {
  type: 'text'
};
