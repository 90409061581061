import gql from 'graphql-tag';

const queries = {

  ORDER: gql`
    query($input: String) {
      order(identification: $input) {
        seller {
          id
          presentationName
          bio
          imageUrl
        }
        id
        from
        to
        media {
          id
          publicMediaUrl
          thumbnailUrl
        }
        statuses {
          id
          classification {
            id
            code
            name
          }
        }
      }
    }

  `,

  SELLERS: gql`
    {
      sellers {
        edges {
          node {
            id
            presentationName
            username
            imageUrl
          }
        }
      }
    }
  `,

  CREDIT_CARD: gql`
    {
      creditCards {
        id
        brand
        first6
        last4
        fullname
        transacted
      }
    }
  `
};

export default queries;
