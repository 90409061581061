import React from 'react';
import MainFormController from '../../../components/FormFlow/MainFormController';
import {
  BannerWrapper,
  PresentationArea,
  PhotoWrapper,
  BaloonTipRight,
  BaloonTipUp,
  OrderWrapper
} from './sellerBanner.style';

const SellerBanner = props => {
  const { imageUrl } = props.pageContext.data;
  return (
    <BannerWrapper>
      <PresentationArea>
        <PhotoWrapper style={{ backgroundImage: `url(${imageUrl})` }}>
          <BaloonTipRight />
          <BaloonTipUp />
        </PhotoWrapper>
        <OrderWrapper>
          <MainFormController pageContext={props.pageContext.data} />
        </OrderWrapper>
      </PresentationArea>
    </BannerWrapper>
  );
};

export default SellerBanner;
