import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import loading from '../../images/star_loading.gif';
import AlertMsgs from '../AlertMsgs';
import Input from '../Elements/Input';
import { Icon } from 'react-icons-kit';
import { unlock } from 'react-icons-kit/fa/unlock';

import { useMutation } from '@apollo/react-hooks';
import mutations from '../../datasource/mutations';

import {
  FormContainer,
  BioText,
  FormWrapper,
  ButtonGroup
} from './formFlow.style';
import DonationInfo from './DonationInfo';

const ActivateAccount = ({ nextStep, userStateReducer, pageContext }) => {

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { presentationName, pricing, charityRate, charityEntity } = pageContext;

  const [errors, setErrors] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [infos, setInfos] = useState(null);
  const [formData, setFormData] = useState({
    pinCode: ''
  });

  const onCompleted = data => {
    const { token } = data.activeCodeAccount;
    const { userState } = userStateReducer({ type: 'getState' });

    if (token) {
      // if is logged, active and has profile/email not empty??
      localStorage.setItem('token', token);
      !userState.account.email ? nextStep('profile') : nextStep('payment');
    } else {
      nextStep('signin');
    }
  };

  const onError = error => {
    setErrors(['Ops! Aconteceu algum erro. :(', error.message]);
    setSubmitMessage(null);
  };

  const onCompletedResendCode = data => {
    setInfos(['Seu código de acesso foi reenviado.']);
  };

  const [activateAccount] = useMutation(mutations.ACTIVATE_ACCOUNT, {
    onCompleted,
    onError
  });

  const [resendCode] = useMutation(mutations.RESEND_PIN_CODE, {
    onCompletedResendCode,
    onError
  });

  const handleChange = event => {
    const { name, value } = event.target;

    const updatedFormData = {
      ...formData
    };

    updatedFormData[name] = value;

    setFormData(updatedFormData);
    setErrors(null);
  };

  const resendPinCode = event => {
    event.preventDefault();
    const {
      userState: { account }
    } = userStateReducer({ type: 'getState' });

    const { phoneCountryCode, phoneAreaCode, phoneNumber } = account;
    resendCode({
      variables: {
        input: {
          phoneNumber,
          phoneAreaCode,
          phoneCountryCode
        }
      }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const {
      userState: { account }
    } = userStateReducer({ type: 'getState' });

    const { phoneCountryCode, phoneAreaCode, phoneNumber } = account;
    
    setSubmitMessage(<Image src={loading} alt="Carregando..."></Image>);

    activateAccount({
      variables: {
        input: {
          phoneNumber,
          phoneAreaCode,
          phoneCountryCode,
          code: formData.pinCode
        }
      }
    });
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Fade bottom delay={30}>
        <Heading as="h1" content="Seu telefone" />
        <BioText>Você vai receber um código de ativação via SMS.</BioText>
        <FormWrapper>
          <Input
            type="text"
            name="pinCode"
            label="Código recebido por mensagem de texto:"
            placeholder="Código (6 números)"
            icon={<Icon icon={unlock} />}
            required={true}
            value={formData.pinCode}
            onChange={handleChange}
            aria-label="pinCode"
          />
          <AlertMsgs errors={errors} infos={infos} />

          <ButtonGroup>
            <Button
              type="submit"
              colors="primaryWithBg"
              title={ submitMessage ? submitMessage : 'CONFIRMAR CÓDIGO' }
              disabled={ submitMessage ? true : false }
            />

            <DonationInfo
              presentationName={presentationName}
              charityRate={charityRate}
              charityEntity={charityEntity}
              pricing={pricing[0]}
            />
            <Button
              className="button_more"
              title="REENVIAR PIN"
              variant="textButton"
              onClick={resendPinCode}
              icon={<i className="flaticon-next" />}
            />
          </ButtonGroup>
        </FormWrapper>
      </Fade>
    </FormContainer>
  );
};

export default ActivateAccount;
