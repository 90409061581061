import styled from 'styled-components';
export const HelloFanWrapper = styled.div`
    visibility: ${props => props.active ? 'visible': 'hidden'};
    position: absolute;
    overflow: hidden;
    width: 540px;
    height: 660px;
    left: calc(50% - 270px);
    top: 100px;
    display: block;
    @media (max-width: 768px) {
        max-width: 100%;
        left: auto;
    }
`;

export const HelloFanFrame = styled.iframe`
    width: 100%;
    height: 100%;
`;

export const HelloFanClose = styled.img`
    width: 30px;
    height: 30px;
`;

export const HelloFanCloseLink = styled.a`
    width: 30px;
    height: 30px;
    left: 510px;
    top: 0px;
    position: absolute;

    @media (max-width: 768px) {
        left: calc(100vw - 50px) !important;
    }
`;
