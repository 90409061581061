import styled, { keyframes } from 'styled-components';
import { themeGet } from 'styled-system';

import error from 'common/src/assets/image/error.svg';
import success from 'common/src/assets/image/success.svg';

const shake = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const BannerWrapper = styled.div`
  background-color: ${themeGet('colors.banner', '#171717')};
  @media only screen and (max-width: 1440px) {
  }
  @media only screen and (max-width: 767px) {
  }
`;



export const Container = styled.div`
  width: 100%;
  max-width: 1580px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1600px) {
    padding: 0 81px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0 60px;
  }
  @media only screen and (max-width: 991px) {
    padding: 0 30px;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ContentArea = styled.div`
  width: 595px;
  padding-right: 88px;
  @media only screen and (max-width: 1600px) {
    width: 560px;
  }
  @media only screen and (max-width: 1360px) {
    width: 40%;
  }
  @media only screen and (max-width: 1200px) {
    width: 45%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 50px;
  }
  @media only screen and (max-width: 480px) {
    padding-right: 0;
  }

  h1,
  p {
    color: ${themeGet('colors.heading')};
  }

  button {
    width: 100%;
  }

  h1 {
    margin-bottom: 30px;
    + p {
      margin: 0;
    }
  }
`;

export const SecondaryHighlightedText = styled.p`
  display: flex;
  align-items: center;
  max-width: 334px;
  width: 100%;
  min-height: 28px;
  border-radius: 80px;
  padding: 3px 20px 3px 20px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${themeGet('colors.theme', '#C6C6C6')};
  background-color: ${themeGet('colors.lightBg')};
  margin: 20px 0 20px;

  strong {
    display: inline-flex;
    align-items: center;
    min-width: 51px;
    min-height: 20px;
    padding: 3px 11px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    color: ${themeGet('colors.heading')};
    background-color: ${themeGet('colors.paletteLightBlue')};
    margin: 3px 3px 3px 3px;
  }
`;

export const FormWrapper = styled.form`
  margin-top: 45px;
  @media only screen and (max-width: 767px) {
    margin-top: 40px;
  }

  .input_element {
    display: flex;
    align-items: center;
    position: relative;

    input,
    textarea {
      width: 100%;
      border: 0;
      font-size: 16px;
      padding: 20px 25px 20px 65px;
      border-radius: 5px;
      color: ${themeGet('colors.black')};
      background-color: ${themeGet('colors.paletteYellow')};

      &::placeholder {
        color: ${themeGet('colors.lightText', '#7E7E7E')};
      }
    }

    textarea {
      height: 7em;
      line-height: 24px;
    }

    .input-icon {
      position: absolute;
      left: 22px;
      top: 18px;

      i {
        color: ${themeGet('colors.lightText', '#7E7E7E')};
        svg {
          width: auto;
          height: 24px;
        }
      }
    }

    &::after {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: calc(50% - 16px / 2);
      right: 25px;
    }

    &.invalid {
      &::after {
        background-image: url(${error});
      }
    }
    &.valid {
      &::after {
        background-image: url(${success});
      }
    }
  }
`;

export const PresentationArea = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1200px) {
    margin-top: 0px;
    flex-direction: column;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 0px;
    flex-direction: column;
  }
`;

export const BaloonTipRight = styled.div`
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-right: 50px solid ${themeGet('colors.paletteLightBlue')};

  @media only screen and (max-width: 1200px) {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const BaloonTipUp = styled.div`
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid ${themeGet('colors.paletteLightBlue')};
  display: none;

  @media only screen and (max-width: 1200px) {
    display: block;
  }

  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

export const PhotoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  min-height: 600px;

  @media only screen and (max-width: 1600px) {
    min-height: 500px;
  }
  @media only screen and (max-width: 1360px) {
    min-height: 400px;
  }
  @media only screen and (max-width: 1200px) {
    margin-top: 80px;
    justify-content: center;
    align-items: flex-end;
    min-height: 500px;
  }

  @media only screen and (max-width: 767px) {
    justify-content: center;
    align-items: flex-end;
    min-height: 500px;
  }

  @media only screen and (max-width: 480px) {
    justify-content: center;
    align-items: flex-end;
    min-height: 300px;
  }
`;

export const OrderWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  min-height:850px;

  @media only screen and (max-width: 1200px) {
    min-height:auto;
  }

  flex-direction: column;
  overflow: scroll;

  color: ${themeGet('colors.white')};
  background-color: ${themeGet('colors.paletteLightBlue')};

  p {
    max-width: 600px;
    color: ${themeGet('colors.white')};
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 100%;
  }

  .input_element {
    margin-bottom: 10px;
  }

  button {
    width: 100%;
  }

  .button_more {
    color: ${themeGet('colors.white')};
  }

  .inactive {
    display: none;
  }

  .active {
    display: block;
  }
`;

export const FormContainer = styled.div`
  flex: 1;

  padding: 60px 100px 40px 100px;

  @media only screen and (max-width: 480px) {
    padding: 20px 30px 20px 30px;
  }
`;

export const MessageFormContainer = styled.div`
  flex: 1;

  padding: 60px 100px 40px 100px;

  @media only screen and (max-width: 480px) {
    padding: 20px 30px 20px 30px;
  }
`;

export const RegistrationFormContainer = styled.div`
  flex: 1;

  padding: 60px 100px 40px 100px;

  @media only screen and (max-width: 480px) {
    padding: 20px 30px 20px 30px;
  }
`;

export const PersonalDataForm = styled.div`
  flex: 1;

  padding: 60px 100px 40px 100px;

  @media only screen and (max-width: 480px) {
    padding: 20px 30px 20px 30px;
  }
`;

export const LoginForm = styled.div`
  flex: 1;

  padding: 60px 100px 40px 100px;

  @media only screen and (max-width: 480px) {
    padding: 20px 30px 20px 30px;
  }
`;

export const AddressDataForm = styled.div`
  flex: 1;

  padding: 60px 100px 40px 100px;

  @media only screen and (max-width: 480px) {
    padding: 20px 30px 20px 30px;
  }
`;

export const CardDataForm = styled.div`
  flex: 1;

  padding: 60px 100px 40px 100px;

  @media only screen and (max-width: 480px) {
    padding: 20px 30px 20px 30px;
  }
`;

export const ProfessionText = styled.div`
  font-weight: normal;
  font-style: normal;
  font-family: 'Gilroy-Light', 'Raleway', sans-serif;
  font-size: 30px;
  margin-bottom: 30px;

  @media only screen and (max-width: 1440px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const ErrorWrapper = styled.div`
  img {
    width: 16px;
    height: 16px;
    display: none;
  }

  span {
    margin-left: 8px;
    display: none;
  }

  .invalid {
    display: inline;
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 50px;
  @media only screen and (max-width: 767px) {
    margin-top: 25px;
    margin-bottom: 54px;
  }

  .reusecore__button {
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    &:first-child {
      margin-right: 20px;
      &:hover {
        opacity: 0.95;
      }
    }

    &:hover {
      .btn-icon {
        animation: ${shake} 1s infinite;
      }
    }
  }
`;
