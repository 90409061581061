import Payment from 'payment';
import { MoipCreditCard } from 'moip-sdk-js';
import JSEncrypt from 'node-jsencrypt';

const pubKey = process.env.MOIP_API_PUBKEY;

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

export async function generateHash(
  number,
  cvc,
  expirationMonth,
  expirationYear
) {
  const hashCode = await MoipCreditCard.setEncrypter(JSEncrypt, 'node')
    .setPubKey(pubKey)
    .setCreditCard({
      number,
      cvc,
      expirationMonth,
      expirationYear
    })
    .hash()
    .then(hash => hash);

  return hashCode;
}

export async function getCreditCardParams({
  issuer,
  number,
  cvc,
  expiry,
  name,
  owner,
  cpfCnpj,
  birthdate,
  phoneNumber,
}) {
  // Format numbers
  const fNumber = number.replace(/\s/g, '');
  const first6 = parseInt(fNumber.slice(0, 6));
  const last4 = parseInt(fNumber.slice(fNumber.length - 4, fNumber.length));

  let formatedDoc = null;
  let birthdateParts = null;
  let parsedDate = null;
  let formatedPhone = null;

  if( owner === "other" ) {
    formatedDoc = cpfCnpj ? cpfCnpj.replace(/\D+/g, '') : null;
    
    birthdateParts = birthdate.split('/');
    parsedDate = new Date(birthdateParts[2], birthdateParts[1] - 1, birthdateParts[0]);

    formatedPhone = formatPhoneNumber(phoneNumber);
  }

  // Format date and generate hash
  const parts = expiry.split('/');
  const expirationMonth = parts[0];
  const expirationYear = parts[1];
  const hash = await generateHash(number, cvc, expirationMonth, expirationYear);

  // Format params
  const params = {
    hash,
    brand: issuer.toUpperCase(),
    first6,
    last4,
    fullname: name,
    cpfCnpj: formatedDoc,
    birthdate: parsedDate,
    phoneCountryCode: formatedPhone ? formatedPhone.phoneCountryCode: null,
    phoneAreaCode: formatedPhone ? formatedPhone.phoneAreaCode: null,
    phoneNumber: formatedPhone ? formatedPhone.phoneNumber: null,
  };

  return params;
}

export function formatPrice (prefix, price) {
  return `${prefix} ${price.toFixed(2).toString().replace('.', ',')}`;
}

export function resolveDonation (charityRate, pricing) {
  const donation = (charityRate * 0.75) * pricing.price;
  return formatPrice(pricing.currency.prefix, donation);
}

export function convertToNumber(part) {
  const ERROR_MSG = 'Número de telefone inválido.';
  if (!part.includes('_')) {
    part = parseInt(part);
  } else {
    // setErrors([ERROR_MSG]);
    throw ERROR_MSG;
  }
  return part;
};

export function formatPhoneNumber(phone) {
  //Slice based on the mask +55 (99) 99999-9999
  const phoneCountryCode = convertToNumber(phone.slice(1, 3));
  const phoneAreaCode = convertToNumber(phone.slice(5, 7));
  const phoneNumber = convertToNumber(
    phone.slice(9, 14) + phone.slice(15, 19)
  );

  return {
    phoneCountryCode,
    phoneAreaCode,
    phoneNumber
  };
};
