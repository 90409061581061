import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Input from '../Elements/Input';
import AlertMsgs from '../AlertMsgs';
import { Icon } from 'react-icons-kit';
import { mail, lock } from 'react-icons-kit/feather';

import { FormContainer, BioText, FormWrapper } from './formFlow.style';

const UserLogin = ({ nextStep }) => {
  const [errors, setErrors] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleChange = event => {
    const { name, value } = event.target;

    const updatedFormData = {
      ...formData
    };

    updatedFormData[name] = value;
    setFormData(updatedFormData);
    setErrors(null);
  };

  const handleSubmit = event => {
    event.preventDefault();

    nextStep();
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Fade bottom delay={30}>
        <Heading as="h1" content="Login" />
        <BioText>Informe seu email e senha</BioText>
        <FormWrapper>
          <Input
            type="email"
            name="email"
            placeholder="e-mail"
            icon={<Icon icon={mail} />}
            required={true}
            value={formData.email}
            onChange={handleChange}
            aria-label="E-mail"
          />
          <Input
            type="password"
            name="password"
            placeholder="Sua senha"
            icon={<Icon icon={lock} />}
            required={true}
            value={formData.password}
            onChange={handleChange}
            aria-label="Senha"
          />
          <AlertMsgs errors={errors} />
          <Button
            type="submit"
            colors="primaryWithBg"
            aria-label="Entrar"
            title="Entrar"
          />
        </FormWrapper>
      </Fade>
    </FormContainer>
  );
};

export default UserLogin;
