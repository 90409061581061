import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import loading from '../../images/star_loading.gif';
import AlertMsgs from '../AlertMsgs';
import { formatPrice, resolveDonation } from './PaymentForm/utils';

import { useMutation } from '@apollo/react-hooks';
import mutations from '../../datasource/mutations';
import { FormContainer, FormWrapper, TextLabel, TextData, TextContainer, ButtonGroup } from './formFlow.style';

const Checkout = ({ nextStep, userStateReducer, pageContext }) => {

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [submitMessage, setSubmitMessage] = useState(null);
  const { userState } = userStateReducer({ type: 'getState' });
  const { salve, seller, payment } = userState;
  const cvc = payment && payment.cvc ? payment.cvc : null;

  const { pricing, charityEntity, presentationName } = pageContext;

  const [errors, setErrors] = useState(
    salve !== null ? null : ['Ocorreu um erro ao carregar sua compra.']
  );

  const onCompletOrder = ({ createOrder }) => {
    if (createOrder && createOrder.order) {

      userState.order = createOrder.order;
      userStateReducer({ type: 'setState' }, userState);

      const orderId = createOrder.order.id;
      createPayment({
        variables: {
          input: {
            orderId,
            cvc
          }
        }
      });
    }
  };

  const onCompletedPayment = data => {
    nextStep('success');
  };

  const onErrorOrder = error => {
    setSubmitMessage(null);
    setErrors([
      'Ops! Aconteceu algum erro no registro da sua ordem. :(',
      error.message
    ]);
  };

  const onErrorPayment = error => {
    setSubmitMessage(null);
    setErrors([
      'Ops! Aconteceu algum erro no registro do seu pagamento. :(',
      error.message
    ]);
  };
  const [createOrder] = useMutation(mutations.CREATE_ORDER, {
    onCompleted: onCompletOrder,
    onError: onErrorOrder
  });

  const [createPayment] = useMutation(mutations.CREATE_PAYMENT, {
    onCompleted: onCompletedPayment,
    onError: onErrorPayment
  });

  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitMessage(<Image src={loading} alt="Carregando..."></Image>);

    createOrder({
      variables: {
        input: {
          sellerId: seller.id,
          public: 'true',
          from: salve.from,
          to: salve.to,
          instructions: salve.message
        }
      }
    });
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Fade bottom delay={30}>
        <Heading as="h1" content="Detalhes de sua encomenda" />

        <FormWrapper>
          {salve !== null && (
            <React.Fragment>
              <TextContainer>
                <TextLabel>De: </TextLabel><TextData>{salve.from}</TextData>
              </TextContainer>
              <TextContainer>
                <TextLabel>Para: </TextLabel><TextData>{salve.to}</TextData>
              </TextContainer>
              <TextContainer>
                <TextLabel>Instruções: </TextLabel><br /><TextData>{salve.message}</TextData>
              </TextContainer>
              <TextContainer>
                <TextLabel>Doação de {presentationName}: </TextLabel><br /><TextData>{charityEntity.businessName} ({resolveDonation (seller.charityRate, pricing[0])})</TextData>
              </TextContainer>
            </React.Fragment>
          )}

          <AlertMsgs errors={errors} />

          <ButtonGroup>
            <Button
              type="submit"
              colors="primaryWithBg"
              aria-label="Entrar"
              title={ submitMessage ? submitMessage : `PAGAR O VALOR DE ${formatPrice(pricing[0].currency.prefix, pricing[0].price)}` }
              disabled={ submitMessage ? true : false }
            />
          </ButtonGroup>
        </FormWrapper>
      </Fade>
    </FormContainer>
  );
};

export default Checkout;
