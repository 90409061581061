import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import loading from '../../images/star_loading.gif';
import Input from '../Elements/Input';
import AlertMsgs from '../AlertMsgs';

import { Icon } from 'react-icons-kit';
import { user, calendar, businessCard, mail } from 'react-icons-kit/typicons'
import { string, object } from 'yup';

import { useMutation } from '@apollo/react-hooks';
import mutations from '../../datasource/mutations';

import {
  FormContainer,
  BioText,
  FormWrapper,
  ButtonGroup
} from './formFlow.style';

const schema = object().shape({
  name: string()
    .min(5, 'O nome deve conter pelo menos 5 caracteres.')
    .required('Informe seu nome completo.'),
  birthdate: string().required('Informe sua data de nascimento.'),
  email: string()
    .email('E-mail inválido.')
    .required('Informe seu email.'),
  cpfCnpj: string().required('Informe seu CPF ou CNPJ')
});

const PersonProfile = ({ nextStep, userStateReducer }) => {

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [errors, setErrors] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    birthdate: '',
    email: '',
    cpfCnpj: ''
  });

  const onCompleted = () => {
    nextStep('payment');
  };

  const onError = error => {
    setSubmitMessage(null);
    setErrors(['Ops! Aconteceu algum erro. :(', error.message]);
  };

  const [createPerson] = useMutation(mutations.CREATE_PERSON, {
    onCompleted,
    onError
  });

  const handleChange = event => {
    const { name, value } = event.target;

    const updatedFormData = {
      ...formData
    };

    updatedFormData[name] = value;

    setFormData(updatedFormData);
    setErrors(null);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { name, birthdate, cpfCnpj, email } = formData;

    let parts = birthdate.split('/');
    let parsedDate = new Date(parts[2], parts[1] - 1, parts[0]);

    parts = cpfCnpj.split('.');
    parts = parts[0] + parts[1] + parts[2];
    parts = parts.split('-');
    parts = parts[0] + parts[1];
    let parsedDoc = parts;

    const result = await schema
      .validate(formData, { abortEarly: false })
      .catch(err => err);

    if (!result.errors) {

      setSubmitMessage(<Image src={loading} alt="Carregando..."></Image>);
      
      const person = {
        name,
        email,
        cpfCnpj: parsedDoc
      };

      let { userState } = userStateReducer({ type: 'getState' });
      userState.person = person;
      userStateReducer({ type: 'setState' }, userState);

      createPerson({
        variables: {
          input: {
            name,
            birthdate: parsedDate,
            email,
            cpfCnpj: parsedDoc
          }
        }
      });
    } else {
      setSubmitMessage(null);
      setErrors(result.errors);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Fade bottom delay={30}>
        <Heading as="h1" content="Dados de quem compra" />
        <BioText>Preencha as suas informações, como pessoa compradora do Salve:</BioText>
        <FormWrapper>
          <Input
            type="text"
            name="name"
            label="Seu nome:"
            placeholder="Digite o seu nome"
            icon={<Icon icon={user} />}
            required={true}
            value={formData.name}
            onChange={handleChange}
            aria-label="name"
          />
          <Input
            type="text"
            name="birthdate"
            label="Data de nascimento:"
            placeholder="Digite a sua data de nascimento"
            mask="99/99/9999"
            icon={<Icon icon={calendar} />}
            required={true}
            value={formData.birthdate}
            onChange={handleChange}
            aria-label="birthdate"
          />
          <Input
            type="text"
            name="cpfCnpj"
            label="Seu CPF:"
            placeholder="Digite o seu CPF"
            mask="999.999.999-99"
            icon={<Icon icon={businessCard} />}
            required={true}
            value={formData.cpfCnpj}
            onChange={handleChange}
            aria-label="cpfCnpj"
          />
          <Input
            type="email"
            name="email"
            label="E-mail:"
            placeholder="Digite seu e-mail"
            icon={<Icon icon={mail} />}
            required={true}
            value={formData.email}
            onChange={handleChange}
            aria-label="email"
          />

          <AlertMsgs errors={errors} />

          <ButtonGroup>
            <Button
              type="submit"
              colors="primaryWithBg"
              title={ submitMessage ? submitMessage : 'CONTINUAR PARA DADOS DO CARTÃO' }
              disabled={ submitMessage ? true : false }
            />
          </ButtonGroup>
        </FormWrapper>
      </Fade>
    </FormContainer>
  );
};

export default PersonProfile;
