import styled, { keyframes } from 'styled-components';
import { themeGet } from 'styled-system';
import error from 'common/src/assets/image/error.svg';
import success from 'common/src/assets/image/success.svg';

const shake = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const FormContainer = styled.form`
  flex: 1;

  padding: 60px 100px 40px 100px;

  @media only screen and (max-width: 480px) {
    padding: 20px 30px 20px 30px;
  }

  .rccs {
    margin-top: 30px;
  }
`;

export const BioText = styled.div`
  font-weight: normal;
  font-style: normal;
  font-family: 'Gilroy-Light', 'Raleway', sans-serif;
  font-size: 30px;
  margin-bottom: 30px;

  @media only screen and (max-width: 1440px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const TextContainer = styled.div`
  padding-bottom: 16px;
`;

export const TextLabel = styled.span`
  font-weight: normal;
  font-style: normal;
  font-family: 'Gilroy-ExtraBold', 'Raleway', sans-serif;
  font-size: 30px;
  margin-bottom: 30px;

  @media only screen and (max-width: 1440px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const TextData = styled.span`
  font-weight: normal;
  font-style: normal;
  font-family: 'Gilroy-Light', 'Raleway', sans-serif;
  font-size: 30px;
  margin-bottom: 30px;

  @media only screen and (max-width: 1440px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const DonationText = styled.div`
  text-align: center;
  padding: 20px 0 30px 0;
  margin: 0;
  font-family: 'Gilroy-Light', 'Raleway', sans-serif;
  font-size: 22px;
  line-height: 28px;

  @media only screen and (max-width: 1440px) {
    font-size: 19px;
    line-height: 24px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 17px;
    line-height: 22px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 21px;
  }

`;

export const OrderWrapper = styled.div`
  margin-top: 45px;
  @media only screen and (max-width: 767px) {
    margin-top: 40px;
  }
`;

export const OptionsWrapper = styled.div`
  margin-top: 20px;
  
  svg {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  a {
    color: ${themeGet('colors.white')};
  }

  strong {
    font-weight: normal;
    text-decoration: underline;
  }
`;

export const FormWrapper = styled.div`
  margin-top: 45px;
  @media only screen and (max-width: 767px) {
    margin-top: 40px;
  }

  .mine {
    display: none;
  }

  .other {
    display: block;
  }

  .input_element {
    display: flex;
    align-items: center;
    position: relative;

    input,
    textarea {
      width: 100%;
      border: 0;
      font-size: 16px;
      padding: 25px 25px 15px 65px;
      border-radius: 5px;
      color: ${themeGet('colors.black')};
      background-color: ${themeGet('colors.paletteYellow')};

      &::placeholder {
        color: ${themeGet('colors.lightText')};
      }
    }
    
    @media only screen and (max-width: 991px) {
      input,
      textarea {
        font-size: 14px;
        line-height: 19px;
      }
    }

    textarea {
      height: 7em;
      line-height: 24px;
    }
    
    .input-label {
      position: absolute;
      left: 65px;
      top: 2px;
      padding-top: 4px;

      background-color: ${themeGet('colors.paletteYellow')};
      color: ${themeGet('colors.black')};
      font-size: 12px;
    }

    .input-icon {
      position: absolute;
      left: 22px;
      top: 18px;

      i {
        color: ${themeGet('colors.black')};
        svg {
          width: auto;
          height: 24px;
        }
      }
    }

    &::after {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: calc(50% - 16px / 2);
      right: 25px;
    }

    &.invalid {
      &::after {
        background-image: url(${error});
      }
    }
    &.valid {
      &::after {
        background-image: url(${success});
      }
    }
  }
`;

export const LegalDisclaimer = styled.div`
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 16px;
  a {
    color: ${themeGet('colors.white')};
    text-decoration: underline;
  }
`;

export const TemporarilyUnavailable = styled.div`
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 16px;
  text-align: center;
  a {
    color: ${themeGet('colors.white')};
    text-decoration: underline;
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 20px;
  @media only screen and (max-width: 767px) {
    margin-top: 25px;
    margin-bottom: 54px;
  }

  .reusecore__button {
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    &:first-child {
      margin-right: 20px;
      &:hover {
        opacity: 0.95;
      }
    }

    &:hover {
      .btn-icon {
        animation: ${shake} 1s infinite;
      }
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  .form-control:first-child {
    margin-right: 10px;
  }
`;

export const InputOwnershipGroup = styled.div`
  padding-top: 14px;
`;

export const InputRadioGroup = styled.div`
  line-height: 30px;
`;
