import React, { useState, useEffect } from 'react';
import queries from '../../../datasource/queries';
import { useQuery } from '@apollo/react-hooks';

import CreateCreditCard from './CreateCreditCard';
import ConfirmCreditCard from './ConfirmCreditCard';

let cardInfo = {
  cvc: '',
  expiry: '',
  name: '',
  number: '',
  focused: '',
  issuer: '',
  hash: '',
  cpfCnpj: '',
  edit: false
};

const PaymentForm = ({ nextStep, userStateReducer }) => {
  const { data } = useQuery(queries.CREDIT_CARD);
  const [page, setPage] = useState(null);
  const [forceRedirect, setForceRedirect] = useState(false);

  useEffect(() => {
    loadCard();
  });

  const loadCard = () => {
    if (!forceRedirect && (data && data.creditCards)) {
      const { first6, last4, fullname, brand, hash } = data.creditCards;
      cardInfo['number'] = first6 + '••••••' + last4;
      cardInfo['name'] = fullname;
      cardInfo['issuer'] = brand.toLowerCase();
      cardInfo['hash'] = hash;
      setPage('confirmate');
    } else {
      setPage('create');
    }
  };

  const showCreateCardPage = () => {
    setForceRedirect(true);
    setPage('create');
  };

  const showCheckoutPage = () => {
    nextStep('checkout');
  };

  const handleNavigation = page => {
    switch (page) {
      case 'confirmate':
        return (
          <ConfirmCreditCard
            cardInfo={cardInfo}
            nextStep={showCheckoutPage}
            userStateReducer={userStateReducer}
            edit={showCreateCardPage}
          />
        );

      case 'create':
        return (
          <CreateCreditCard
            nextStep={showCheckoutPage}
            userStateReducer={userStateReducer}
          />
        );
      default:
        return null;
    }
  };

  return handleNavigation(page);
};

export default PaymentForm;
