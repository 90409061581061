import React from 'react';
import { Component } from 'react';
import { HelloFanWrapper, HelloFanFrame, HelloFanClose, HelloFanCloseLink } from './hellofan.style';

class HelloFan extends Component 
{
  constructor(props) {
    super(props);
    this.state = {
      modalActive: true,
    }
  }

  render() {
    return (
      <HelloFanWrapper
        active={this.state.modalActive}
      >
          <HelloFanCloseLink
            onClick={() => {
              this.setState({
                modalActive: false
              });
            }}
          >
            <HelloFanClose
              src={'/images/close-button.svg'}
            />
          </HelloFanCloseLink>
        <HelloFanFrame
            title="Hello Fan"
            src="/hello_fan.html"
            frameBorder="0"
            scrolling="no"
        >
        </HelloFanFrame>
      </HelloFanWrapper>
    );
  };
};

export default HelloFan;
