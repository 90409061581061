import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import loading from '../../../images/star_loading.gif';
import Input from '../../Elements/Input';
import AlertMsgs from '../../AlertMsgs';
import Card from 'react-credit-cards';

import { Icon } from 'react-icons-kit';
import { creditCardAlt } from 'react-icons-kit/fa/creditCardAlt';

import { formatCVC } from './utils';

import {
  FormContainer,
  FormWrapper,
  BioText,
  ButtonGroup
} from '../formFlow.style';
import 'react-credit-cards/es/styles-compiled.css';

const ConfirmCreditCard = ({ cardInfo, nextStep, userStateReducer, edit }) => {

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [errors] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [formData, setFormData] = useState(cardInfo);

  const handleInputFocus = ({ target }) => {
    const { name } = target;
    const updatedFormData = {
      ...formData
    };

    updatedFormData['focused'] = name;
    setFormData(updatedFormData);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData
    };

    event.target.value = formatCVC(value);

    updatedFormData[name] = value;
    setFormData(updatedFormData);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { userState } = userStateReducer({ type: 'getState' });
    const { cvc, hash } = formData;
    userState.payment = {
      hash,
      cvc
    };

    setSubmitMessage(<Image src={loading} alt="Carregando..."></Image>);

    userStateReducer({ type: 'setState' }, userState);
    nextStep();
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Fade bottom delay={30}>
        <Heading as="h1" content="Dados de pagamento" />
        <BioText>Confirme os dados do seu cartão.</BioText>
        <Card
          number={formData.number}
          cvc={formData.cvc}
          expiry={formData.expiry}
          name={formData.name}
          issuer={formData.issuer}
          focused={formData.focused}
        />

        <FormWrapper>
          <Input
            type="tel"
            name="cvc"
            className="form-control"
            label="CVC do cartão:"
            placeholder="Atrás do cartão"
            required
            max="3"
            icon={<Icon icon={creditCardAlt} />}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <input type="hidden" name="issuer" value={formData.issuer} />

          <AlertMsgs errors={errors} />

          <ButtonGroup>
            <Button
              type="submit"
              colors="primaryWithBg"
              title={ submitMessage ? submitMessage : 'CONFIRMAR CARTÃO' }
              disabled={ submitMessage ? true : false }
            />

            <Button
              className="button_more"
              title="TROCAR CARTÃO"
              variant="textButton"
              onClick={edit}
              icon={<i className="flaticon-next" />}
            />
          </ButtonGroup>
        </FormWrapper>
      </Fade>
    </FormContainer>
  );
};

export default ConfirmCreditCard;
