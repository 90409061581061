import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Heading from 'reusecore/src/elements/Heading';
import BlogPost from 'common/src/components/BlogPost';
import Container from 'common/src/components/UI/Container';
import { SectionHeader } from '../interior.style';
import SectionWrapper, { FeatureWrapper } from '../Feature/feature.style';

const SellerFeature = props => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        sellerFeatureData {
          title
          slogan
          features {
            id
            icon {
              publicURL
            }
            title
            description
            descriptionFullDonor
          }
        }
      }
    }
  `);

  const { title, slogan, features } = data.interiorJson.sellerFeatureData;

  const {
    charityEntity,
    charityRate,
    presentationName
  } = props.pageContext.data;

  const formatCharityRate = percentual => {
    return `${Math.round((percentual * 0.75) * 100)}%`;
  };

  return (
    <SectionWrapper id="sellerFeature">
      <Fade bottom>
        <SectionHeader>
          <Heading as="h5" content={title} />
          <Heading
            content={slogan.replace('{name}', presentationName)}
          />
        </SectionHeader>
      </Fade>
      <Container width="1360px">
        <Fade bottom delay={30}>
          <FeatureWrapper>
            {features.map(item => {

              let description;

              if (charityRate === 1.0 && item.descriptionFullDonor.length > 0) {
                description = item.descriptionFullDonor
              } else {
                description = item.description
              }
 
              description = description.replace(
                '{name}',
                `${presentationName}`
              );
              description = description.replace(
                '{charityRate}',
                formatCharityRate(charityRate)
              );
              description = description.replace(
                '{charityEntity}',
                charityEntity.businessName
              );

              return (
                <BlogPost
                  key={`feature_key${item.id}`}
                  thumbUrl={item.icon.publicURL}
                  title={item.title}
                  excerpt={description}
                />
              );
            })}
          </FeatureWrapper>
        </Fade>
      </Container>
    </SectionWrapper>
  );
};

export default SellerFeature;
