import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import loading from '../../../images/star_loading.gif';
import Input from '../../Elements/Input';
import AlertMsgs from '../../AlertMsgs';
import Card from 'react-credit-cards';
import mutations from '../../../datasource/mutations';
import { string, mixed, object } from 'yup';
import { useMutation } from '@apollo/react-hooks';

import { Icon } from 'react-icons-kit';
import { creditCardAlt } from 'react-icons-kit/fa';
import { calendar, businessCard } from 'react-icons-kit/typicons';
import { ic_phone_iphone } from 'react-icons-kit/md';

import { user } from 'react-icons-kit/typicons'

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  getCreditCardParams
} from './utils';

import {
  FormContainer,
  FormWrapper,
  InputGroup,
  InputOwnershipGroup,
  InputRadioGroup,
  ButtonGroup,
} from '../formFlow.style';
import 'react-credit-cards/es/styles-compiled.css';

const schema = object().shape({
  name: string()
    .min(5, 'O nome da pessoa titular deve conter pelo menos 5 caracteres.')
    .max(22, 'O nome da pessoa titular no cartão não pode ter mais de 22 caracteres.')
    .required('Informe nome do titular do cartão.'),
  issuer: mixed().notOneOf(
    ['unknown', 'UNKNOWN'],
    'Cartão não reconhecido. Certifique que você digitou o número do cartão corretamente.'
  ),
  cvc: string().max(4, 'O código de segurança deve conter até 4 caracteres.'),
  expiry: string()
    .max(5)
    .matches(/([0-9]{2})\/([0-9]{2})/, 'Data de expiração inválida.')
    .test('isValidData', 'Data de expiração inválida.', value => {
      const parts = value.split('/');
      const isValidMonth = parts[0] > 12 ? false : true;
      const isValidYear = parts[1] < 19 ? false : true;
      return isValidMonth && isValidYear;
    })
    .required('Informe a data de expiração do cartão.'),
});

const schemaComplete = object().shape({
  name: string()
    .min(5, 'O nome da pessoa titular deve conter pelo menos 5 caracteres.')
    .max(22, 'O nome da pessoa titular no cartão não pode ter mais de 22 caracteres.')
    .required('Informe nome do titular do cartão.'),
  issuer: mixed().notOneOf(
    ['unknown', 'UNKNOWN'],
    'Cartão não reconhecido. Certifique que você digitou o número do cartão corretamente.'
  ),
  cvc: string().max(4, 'O código de segurança deve conter até 4 caracteres.'),
  expiry: string()
    .max(5)
    .matches(/([0-9]{2})\/([0-9]{2})/, 'Data de expiração inválida.')
    .test('isValidData', 'Data de expiração inválida.', value => {
      const parts = value.split('/');
      const isValidMonth = parts[0] > 12 ? false : true;
      const isValidYear = parts[1] < 19 ? false : true;
      return isValidMonth && isValidYear;
    })
    .required('Informe a data de expiração do cartão.'),
  cpfCnpj: string().required('Informe seu CPF ou CNPJ'),
  birthdate: string().required('Informe sua data de nascimento.'),
  phoneNumber: string()
  .typeError('Deve ser um número.')
  .required('Informe o número do seu telefone com DDD'),
});

const CreateCreditCard = ({ nextStep, userStateReducer }) => {

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [errors, setErrors] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [formData, setFormData] = useState({
    cvc: '',
    expiry: '',
    name: '',
    number: '',
    focused: '',
    issuer: '',
    owner: 'mine',
    cpfCnpj: '',
    birthdate: '',
    phoneNumber: '',
  });

  const onCompleted = data => {
    nextStep();
  };

  const onError = error => {
    setSubmitMessage(null);
    setErrors(['Ops! Aconteceu algum erro. :(', error.message]);
  };

  const [createCreditCard] = useMutation(mutations.CREATE_CREDIT_CARD, {
    onCompleted,
    onError
  });

  const handleCallback = ({ issuer }, isValid) => {
    const updatedFormData = {
      ...formData
    };

    updatedFormData['issuer'] = issuer;
    setFormData(updatedFormData);
  };

  const handleInputFocus = ({ target }) => {
    const { name } = target;
    const updatedFormData = {
      ...formData
    };
    updatedFormData['focused'] = name;
    setFormData(updatedFormData);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData
    };

    switch (name) {
      case 'name':
        event.target.value = value.toUpperCase();
        break;
      case 'number':
        const fNumber = formatCreditCardNumber(value);
        event.target.value = fNumber;
        break;
      case 'expiry':
        const fExpiry = formatExpirationDate(value);
        event.target.value = fExpiry;
        break;
      case 'cvc':
        const fCvc = formatCVC(value);
        event.target.value = fCvc;
        break;
      default:
        break;
    }

    updatedFormData[name] = value;
    setFormData(updatedFormData);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const selectedSchema = (formData.owner === 'other') ? schemaComplete : schema;

    const result = await selectedSchema
      .validate(formData, { abortEarly: false })
      .catch(err => err);

    if (!result.errors) {
      const params = await getCreditCardParams(formData);
      setSubmitMessage(<Image src={loading} alt="Carregando..."></Image>);

      createCreditCard({
        variables: {
          input: params
        }
      });
    } else {
      setSubmitMessage(null);
      setErrors(result.errors);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Fade bottom delay={30}>
        <Heading as="h1" content="Dados de pagamento" />
        <Card
          number={formData.number}
          cvc={formData.cvc}
          expiry={formData.expiry}
          name={formData.name}
          issuer={formData.issuer}
          focused={formData.focused}
          callback={handleCallback}
          placeholders={{ name: '' }}
        />

        <FormWrapper>
          <Input
            type="tel"
            name="number"
            label="Número do cartão:"
            placeholder="Digite o número do cartão"
            pattern="[\d| ]{16,22}"
            required
            icon={<Icon icon={creditCardAlt} />}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <Input
            type="text"
            name="name"
            className="form-control"
            label="Nome da pessoa titular do cartão:"
            placeholder="Nome como está escrito no cartão"
            required
            icon={<Icon icon={user} />}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <InputGroup>
            <Input
              type="tel"
              name="expiry"
              className="form-control"
              label="Validade:"
              placeholder="aa/mm"
              pattern="\d\d/\d\d"
              mask="99/99"
              required
              icon={<Icon icon={calendar} />}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />

            <Input
              type="tel"
              name="cvc"
              className="form-control"
              label="CVC:"
              placeholder="Veja atrás"
              required
              max="4"
              icon={<Icon icon={creditCardAlt} />}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </InputGroup>

          <InputRadioGroup>
            <label htmlFor="mine">
              <input
                type="radio"
                name="owner"
                id="mine"
                value="mine"
                defaultChecked 
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
              Este cartão é seu
            </label>

            <br />
            
            <label htmlFor="other">
              <input
                type="radio"
                name="owner" 
                id="other"
                value="other"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
              Alguém cedeu o cartão e autorizou o uso
            </label>
          </InputRadioGroup>

          <InputOwnershipGroup className={formData.owner}>
            <Input
              type="tel"
              name="cpfCnpj"
              label="CPF da pessoa titular do cartão"
              placeholder="Digite o CPF de quem cedeu o cartão"
              mask="999.999.999-99"
              icon={<Icon icon={businessCard} />}
              onChange={handleInputChange}
              required={formData.owner === 'other'}
              aria-label="CPF do titular do cartão"
            />

            <Input
              type="text"
              name="birthdate"
              label="Data de nascimento da pessoa titular do cartão"
              placeholder="Data nascimento da pessoa"
              mask="99/99/9999"
              icon={<Icon icon={calendar} />}
              value={formData.birthdate}
              onChange={handleInputChange}
              required={formData.owner === 'other'}
              aria-label="Data de nascimento do titular"
            />

            <Input
              type="tel"
              name="phoneNumber"
              inputMode="tel"
              label="Telefone celular da pessoa titular:"
              placeholder="Digite o telefone do titular"
              mask="+55 (99) 99999-9999"
              icon={<Icon icon={ic_phone_iphone} />}
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required={formData.owner === 'other'}
              aria-label="Telefone celular do titular"
            />
          </InputOwnershipGroup>


          <input type="hidden" name="issuer" value={formData.issuer} />

          <AlertMsgs errors={errors} />
          <ButtonGroup>
            <Button
              type="submit"
              colors="primaryWithBg"
              title={ submitMessage ? submitMessage : 'CADASTRAR CARTÃO' }
              disabled={ submitMessage ? true : false }
            />
          </ButtonGroup>
        </FormWrapper>
      </Fade>
    </FormContainer>
  );
};

export default CreateCreditCard;
