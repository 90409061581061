import React, { useState, useEffect, Fragment } from 'react';
import Fade from 'react-reveal/Fade';
import { Link, navigate } from "gatsby"
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import loading from '../../images/star_loading.gif';
import Input from '../Elements/Input';
import AlertMsgs from '../AlertMsgs';

import { Icon } from 'react-icons-kit';
import { quoteSerifLeft, heart } from 'react-icons-kit/iconic';
import { ic_contact_mail } from 'react-icons-kit/md';
import { cut } from 'react-icons-kit/fa';

import { string, object } from 'yup';

import {
  FormContainer,
  BioText,
  FormWrapper,
  LegalDisclaimer,
  TemporarilyUnavailable,
  ButtonGroup
} from './formFlow.style';

import { formatPrice } from './PaymentForm/utils';
import DonationInfo from './DonationInfo';

const schema = object().shape({
  from: string()
    .min(3, 'Nome de quem presenteia tá muito curtinho. É isso mesmo?')
    .required('Nome de quem presenteia precisa ser preenchido.'),
  to: string()
    .min(3, 'Tem poucas letras no nome de quem recebe o Salve. Tá tudo certo?.')
    .required('Nome de quem receberá o Salve precisa ser preenchido.'),
  message: string()
    .min(20, `Escreva pelo menos 20 caracteres em suas instruções do Salve.`)
    .max(
      200,
      'Puxa, suas instruções ficaram muito grandes. Você consegue fazer em menos de 200 caracteres?'
    )
    .required('As instruções do Salve precisam ser preenchidas.')
});

const MessageForm = ({ nextStep, userStateReducer, pageContext }) => {

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const {
    presentationName,
    bio,
    pricing,
    charityRate,
    charityEntity,
    temporarilyUnavailable,
  } = pageContext;

  const [errors, setErrors] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [formData, setFormData] = useState({
    from: '',
    to: '',
    message: ''
  });

  const handleChange = event => {
    const { name, value } = event.target;

    const updatedFormData = {
      ...formData
    };

    updatedFormData[name] = value;
    setFormData(updatedFormData);
    setErrors(null);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const result = await schema
      .validate(formData, { abortEarly: false })
      .catch(err => err);

    if (!result.errors) {
      setSubmitMessage(<Image src={loading} alt="Carregando..."></Image>);

      let { userState } = userStateReducer({ type: 'getState' });
      userState.salve = formData;
      userStateReducer({ type: 'setState' }, userState);
      nextStep('signin');
    } else {
      setSubmitMessage(null);
      setErrors(result.errors);
    }
  };

  const handleMoreIdols = event => {
    navigate('/');
  }

  const resolvePriceTag = () => {
    if (pricing.length > 0) {
      const price = formatPrice(pricing[0].currency.prefix, pricing[0].price);
      if (pricing[0].originalPrice) {
        const originalPrice = `${pricing[0].currency.prefix} ${pricing[0].originalPrice}`;
        return (
          <span>
            {` ENCOMENDAR SALVE DE `}
            <Icon icon={cut}></Icon> <strike>{originalPrice}</strike>
            {` POR ${price}`}
          </span>
        );
      }
      return <span>{` ENCOMENDAR SALVE POR ${price}`}</span>;
    }
    return null;
  };

  var sellerActions;

  console.log('temporarilyUnavailable:', temporarilyUnavailable);

  if (!temporarilyUnavailable) {
    sellerActions = (
      <Fragment>
        <LegalDisclaimer>
          Ao clicar no botão de encomendar, você aceita e concorda{' '} 
          com os <Link to="/terms/" target="_blank" rel="noopener noreferrer">Termos e Condições</Link> e{' '}
          <Link to="/privacy/" target="_blank" rel="noopener noreferrer">Política de Privacidade</Link>{' '} 
          do Manda Salve:
        </LegalDisclaimer>

        <Button
          type="submit"
          colors="primaryWithBg"
          title={ submitMessage ? submitMessage : resolvePriceTag() }
          disabled={ submitMessage ? true : false }
        />
        <DonationInfo
          presentationName={presentationName}
          charityRate={charityRate}
          charityEntity={charityEntity}
          pricing={pricing[0]}
        />
      </Fragment>
    )   
  }
  else {
    sellerActions = (
      <Fragment>
        <TemporarilyUnavailable>
          {presentationName} está temporariamente indisponível na plataforma mas retorna em breve!
        </TemporarilyUnavailable>
      </Fragment>  
    );  
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Fade bottom delay={30}>
        <Heading as="h1" content={presentationName} />
        <BioText>{bio}</BioText>
        <FormWrapper>
          <Input
            type="text"
            name="from"
            value={formData.from}
            onChange={handleChange}
            label="Nome de quem presenteia:"
            placeholder="Ex: Joana"
            icon={<Icon icon={ic_contact_mail} />}
            iconPosition="left"
            required={true}
            aria-label="De"
            disabled={temporarilyUnavailable}
          />
          <Input
            type="text"
            name="to"
            value={formData.to}
            onChange={handleChange}
            label="Salve vai ser recebido por:"
            placeholder={`Nome de quem receberá o Salve`}
            icon={<Icon icon={heart} />}
            iconPosition="left"
            required={true}
            aria-label="Para"
            disabled={temporarilyUnavailable}
          />
          <Input
            type="textarea"
            name="message"
            value={formData.message}
            onChange={handleChange}
            label={`Instruções para ${presentationName}:`}
            placeholder={`${presentationName}, é aniversário da Carla e somos seus fãs. Deseje um feliz aniversário para ela!`}
            icon={<Icon icon={quoteSerifLeft} />}
            iconPosition="left"
            required={true}
            aria-label="mensagem"
            disabled={temporarilyUnavailable}
          />

          <AlertMsgs errors={errors} />

          <ButtonGroup>
            {sellerActions}

            <Button
              className="button_more"
              title="VER MAIS ÍDOLOS"
              onClick={handleMoreIdols}
              variant="textButton"
              icon={<i className="flaticon-next" />}
            />
          </ButtonGroup>
        </FormWrapper>
      </Fade>
    </FormContainer>
  );
};

export default MessageForm;
