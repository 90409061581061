import gql from 'graphql-tag';

const mutations = {
  CREATE_ACCOUNT: gql`
    mutation($input: createAccountInput!) {
      createAccount(input: $input) {
        account {
          id
          phoneNumber
          phoneAreaCode
          phoneCountryCode
          email
          status
          updatedAt
          createdAt
        }
      }
    }
  `,

  ACTIVATE_ACCOUNT: gql`
    mutation($input: activeCodeAccountInput!) {
      activeCodeAccount(input: $input) {
        token
      }
    }
  `,

  CREATE_PERSON: gql`
    mutation($input: createPersonInput!) {
      createPerson(input: $input) {
        person {
          id
          name
          birthdate
          cpfCnpj
          account {
            phoneCountryCode
            phoneAreaCode
            phoneNumber
            email
          }
          updatedAt
          createdAt
        }
      }
    }
  `,

  CREATE_CREDIT_CARD: gql`
    mutation($input: createCreditCardInput!) {
      createCreditCard(input: $input) {
        creditCard {
          id
          brand
          first6
          last4
        }
      }
    }
  `,

  CREATE_ORDER: gql`
    mutation($input: CreateOrderInput!) {
      createOrder(input: $input) {
        order {
          id
          public
          amountDiscount
          amountSeller
          amountCharity
          price
          accepted
          from
          to
          instructions
          expiresAt
          createdAt
          updatedAt
          identification
        }
      }
    }
  `,

  CREATE_PAYMENT: gql`
    mutation($input: paymentInput!) {
      createPayment(input: $input) {
        payment {
          id
          status
        }
      }
    }
  `,

  RESEND_PIN_CODE: gql`
    mutation($input: resendCodeAccountInput!) {
      resendCodeAccount(input: $input) {
        id
        status
      }
    }
  `
};

export default mutations;
