import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import loading from '../../images/star_loading.gif';
import Input from '../Elements/Input';
import AlertMsgs from '../AlertMsgs';
import { Icon } from 'react-icons-kit';
import { ic_phone_iphone } from 'react-icons-kit/md';
import { string, object } from 'yup';
import { formatPhoneNumber } from '../FormFlow/PaymentForm/utils';

import { useMutation } from '@apollo/react-hooks';
import mutations from '../../datasource/mutations';

import {
  FormContainer,
  BioText,
  FormWrapper,
  ButtonGroup
} from './formFlow.style';
import DonationInfo from './DonationInfo';

const schema = object().shape({
  phoneNumber: string()
    .typeError('Deve ser um número.')
    .required('Informe o número do seu telefone com DDD')
});

const SignIn = ({ nextStep, userStateReducer, pageContext }) => {

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { presentationName, pricing, charityRate, charityEntity } = pageContext;

  const [errors, setErrors] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [formData, setFormData] = useState({
    phoneNumber: ''
  });

  const onCompleted = data => {
    setErrors(null);
    setFormData({
      phoneNumber: ''
    });

    const { account } = data.createAccount;

    updateUserState(account);
    nextStep('activate');
  };

  const onError = error => {
    setSubmitMessage(null);
    setErrors(['Ops! Aconteceu algum erro. :(', error.message]);
  };

  //TODO: improve api naming?
  const [createAccount] = useMutation(mutations.CREATE_ACCOUNT, {
    onCompleted,
    onError
  });

  const updateUserState = account => {
    const {
      phoneNumber,
      phoneAreaCode,
      phoneCountryCode,
      status,
      email
    } = account;
    let { userState } = userStateReducer({ type: 'getState' });

    userState.account = {
      phoneNumber,
      phoneAreaCode,
      phoneCountryCode,
      status,
      email
    };
    userStateReducer({ type: 'setState' }, userState);
  };

  const handleChange = event => {
    const { name, value } = event.target;

    const updatedFormData = {
      ...formData
    };

    updatedFormData[name] = value;
    setFormData(updatedFormData);
    setErrors(null);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const result = await schema
      .validate(formData, { abortEarly: false })
      .catch(err => err);

    if (!result.errors) {
      const formatedPhone = formatPhoneNumber(formData.phoneNumber);

      setSubmitMessage(<Image src={loading} alt="Carregando..."></Image>);

      createAccount({
        variables: {
          input: formatedPhone
        }
      });
    } else {
      setSubmitMessage(null);
      setErrors(result.errors);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Fade bottom delay={30}>
        <Heading as="h1" content={'Seu telefone'} />
        <BioText>
          Usamos o seu número para entregar o Salve. Você receberá uma mensagem
          de texto com o código de acesso.
        </BioText>
        <FormWrapper>
          <Input
            type="tel"
            name="phoneNumber"
            inputMode="tel"
            label="Telefone celular com DDD:"
            placeholder="Digite o seu telefone."
            mask="+55 (99) 99999-9999"
            icon={<Icon icon={ic_phone_iphone} />}
            required={true}
            value={formData.phoneNumber}
            onChange={handleChange}
            aria-label="pinCode"
          />

          <AlertMsgs errors={errors} />

          <ButtonGroup>
            <Button
              type="submit"
              colors="primaryWithBg"
              title={ submitMessage ? submitMessage : 'INFORMAR TELEFONE' }
              disabled={ submitMessage ? true : false }
            />
            <DonationInfo
              presentationName={presentationName}
              charityRate={charityRate}
              charityEntity={charityEntity}
              pricing={pricing[0]}
            />
          </ButtonGroup>
        </FormWrapper>
      </Fade>
    </FormContainer>
  );
};

export default SignIn;
