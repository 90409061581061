import React from 'react';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { info, xCircle, alertTriangle } from 'react-icons-kit/feather';

import { AlertWrapper } from './AlertMsgs.style';

class AlertMsgs extends React.Component {
  render() {
    const { errors, warnings, infos } = this.props;

    const listErrors = errors
      ? errors.map((msg, index) => (
          <li key={index}>
            <Icon icon={xCircle} /> <span className="error_msg">{msg}</span>
          </li>
        ))
      : null;

    const listWarnings = warnings
      ? warnings.map((msg, index) => (
          <li key={index}>
            <Icon icon={info} /> <span className="warning_msg">{msg}</span>
          </li>
        ))
      : null;

    const listInfos = infos
      ? infos.map((msg, index) => (
          <li key={index}>
            <Icon icon={alertTriangle} />{' '}
            <span className="info_msg">{msg}</span>
          </li>
        ))
      : null;

    return (
      <>
        <Fade>
          <ul>
            {errors && <AlertWrapper>{listErrors}</AlertWrapper>}
            {warnings && <AlertWrapper>{listWarnings}</AlertWrapper>}
            {infos && <AlertWrapper>{listInfos}</AlertWrapper>}
          </ul>
        </Fade>
      </>
    );
  }
}
export default AlertMsgs;
