import React, { Fragment } from 'react';

import { DonationText } from './formFlow.style';

import { resolveDonation } from './PaymentForm/utils';

const DonationInfo = ({presentationName, charityRate, charityEntity, pricing}) => {
  return (
    <DonationText>
    {
      (charityRate === 1.0) ? (
        <Fragment>Para cada Salve encomendado, {presentationName} doa <strong>100% de seu cachê</strong> para {<strong>{charityEntity.businessName}</strong>}.</Fragment>
      ) : (
        <Fragment>Para cada Salve encomendado, {presentationName} doa {<strong>{resolveDonation(charityRate, pricing)}</strong>} para {<strong>{charityEntity.businessName}</strong>}.</Fragment>
      )
    }
    </DonationText>
  );
};

export default DonationInfo;