import React, { useState } from 'react';
import MessageForm from './MessageForm';
import ActivateAccount from './ActivateAccount';
import SignIn from './SignIn';
import PersonProfile from './PersonProfile';
import UserLogin from './UserLogin';
import Checkout from './Checkout';
import Success from './Success';
import PaymentForm from './PaymentForm';

const MainFormController = ({ pageContext }) => {
  const { id, pricing, charityRate, charityEntity } = pageContext;
  const seller = { id, pricing, charityRate, charityEntity };

  const [step, setStep] = useState(1);
  const [userState, setUserState] = useState({
    salve: null,
    account: null,
    seller
  });

  const userStateReducer = (action, value = null) => {
    switch (action.type) {
      case 'getState':
        return { userState };
      case 'setState':
        return value ? setUserState(value) : new Error();
      default:
        throw new Error();
    }
  };

  const mapSteps = new Map([
    ['message', 1],
    ['signin', 2],
    ['activate', 3],
    ['profile', 4],
    ['payment', 5],
    ['checkout', 6],
    ['success', 7],
    ['login', 8]
  ]);

  const nextStep = (jumpToStep = null) => {
    if (jumpToStep && mapSteps.has(jumpToStep)) {
      setStep(mapSteps.get(jumpToStep));
    } else {
      setStep(step + 1);
    }
  };

  const manageState = step => {
    switch (step) {
      case 1:
        return (
          <MessageForm
            nextStep={nextStep}
            userStateReducer={userStateReducer}
            pageContext={pageContext}
          />
        );

      case 2:
        return (
          <SignIn
            nextStep={nextStep}
            userStateReducer={userStateReducer} 
            pageContext={pageContext}
          />
        );

      case 3:
        return (
          <ActivateAccount
            nextStep={nextStep}
            userStateReducer={userStateReducer}
            pageContext={pageContext}
          />
        );

      case 4:
        return (
          <PersonProfile
            nextStep={nextStep}
            userStateReducer={userStateReducer}
            pageContext={pageContext}
          />
        );

      case 5:
        return (
          <PaymentForm
            nextStep={nextStep}
            userStateReducer={userStateReducer}
            pageContext={pageContext}
          />
        );

      case 6:
        return (
          <Checkout
            nextStep={nextStep}
            userStateReducer={userStateReducer} 
            pageContext={pageContext}
          />
        );

      case 7:
        return (
          <Success
            nextStep={nextStep}
            userStateReducer={userStateReducer} 
            pageContext={pageContext}
          />
        );

      case 8:
        return (
          <UserLogin
            nextStep={nextStep}
            userStateReducer={userStateReducer}
            pageContext={pageContext}
          />
        );

      default:
        return null;
    }
  };

  return manageState(step);
};

export default MainFormController;
