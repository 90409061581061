import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { interiorTheme } from 'common/src/theme/interior';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../containers/Interior/Navbar';
import SellerBanner from '../containers/Interior/SellerBanner';
import SellerFeature from '../containers/Interior/SellerFeature';
import HelloFan from '../components/HelloFan';
// gitimport SellerGallery from '../containers/Interior/SellerGallery';
import Footer from '../containers/Interior/Footer';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  InteriorWrapper,
  ContentWrapper
} from '../containers/Interior/interior.style';
import SEO from '../components/seo';

export default props => {
  const { presentationName } = props.pageContext.data;

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <SEO title={presentationName} />
        <ResetCSS />
        <GlobalStyle />

        {/* Start writing your markup from here. */}
        <InteriorWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="sticky-internal">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
            <HelloFan />
          </Sticky>
          <ContentWrapper>
            <SellerBanner {...props} />
            <SellerFeature {...props} />
            {/* <SellerGallery {...props} /> */}
          </ContentWrapper>
          <Footer />
        </InteriorWrapper>
        {/* End of markup section. */}
      </Fragment>
    </ThemeProvider>
  );
};
