import React from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'reusecore/src/elements/Heading';

import { formatPrice, resolveDonation } from './PaymentForm/utils';

import { FormContainer, BioText, TextLabel, TextData, TextContainer } from './formFlow.style';

const Success = ({ userStateReducer, pageContext }) => {

  const { pricing, charityEntity, presentationName } = pageContext;

  const { userState } = userStateReducer({ type: 'getState' });
  const { salve, seller, order } = userState;
  
  return (
    <FormContainer>
      <Fade bottom delay={30}>
        <Heading as="h1" content="Encomenda concluída!" />

        {order !== null && (
            <React.Fragment>
              <TextContainer>
                <TextLabel>Número do pedido: </TextLabel><TextData>{order.identification}</TextData>
              </TextContainer>
              <TextContainer>
                <TextLabel>Resumo da compra: </TextLabel><TextData>1 Salve de {salve.from} para {salve.to}.</TextData>
              </TextContainer>
              <TextContainer>
                <TextLabel>Doação de {presentationName}: </TextLabel><TextData>{charityEntity.businessName} ({resolveDonation (seller.charityRate, pricing[0])})</TextData>
              </TextContainer>
              <TextContainer>
                <TextLabel>Valor total da compra: </TextLabel><TextData>{formatPrice(pricing[0].currency.prefix, pricing[0].price)}</TextData>
              </TextContainer>
            </React.Fragment>
          )}
        <BioText>A atualização do processamento de seu pagamento será enviada ao seu email.</BioText>
      </Fade>
    </FormContainer>
  );
};

export default Success;
